import React, { useState, useEffect } from "react";
import { getMediumNameForProjects,getMediumNameForTask } from "../utensils/simplifier";
import {
  Button,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import getPreviousWeek from "../utensils/prevWeek";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { fetchActiveUsers, fetchUserTimesheetData } from "../api/api1"; // Custom functions to fetch data from your backend
import { getShortNameForProjects, getShortNameForTask } from "../utensils/simplifier"; // Import the simplifier functions

const StyledContainer = styled(Container)({
  backgroundColor: "#FFFFFF",
  padding: "30px",
  borderRadius: "16px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
});
const Title = styled("h1")({
  textAlign: "center",
  color: "#0d47a1",
  marginBottom: "30px",
  fontSize: "2.5rem",
  fontFamily: "Roboto, sans-serif",
});
const UploadSection = styled("div")({
  marginBottom: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});
const ChartContainer = styled(Paper)({
  marginTop: "20px",
  padding: "20px",
  borderRadius: "12px",
  backgroundColor: "#f8f9fa",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const Navbar = styled('div')(({ scrollPosition }) => ({
  position: scrollPosition > 0 ? 'absolute' : 'relative',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFFFFF',
  padding: '20px',
  transition: 'all 0.3s ease',
  opacity: scrollPosition > 0 ? 1 : 0,
  zIndex: 10,
  boxShadow: scrollPosition > 0 ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A6D854"];

const TimesheetDashboard = () => {
  const { start, end } = getPreviousWeek();
  const [timesheetData, setTimesheetData] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [selectedView, setSelectedView] = useState('project'); // state to track the current view
  const [selectedOption, setSelectedOption] = useState('week');
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);


  const calculateTotalHours = () => {
    let totalHours = 0;

    // Iterate through each project and accumulate hours
    Object.keys(timesheetData).forEach((projectId) => {
      const project = timesheetData[projectId];

      Object.keys(project.users).forEach((userId) => {
        const user = project.users[userId];

        Object.keys(user.hours).forEach((taskId) => {
          const task = user.hours[taskId];
          totalHours += parseFloat(task.hour) || 0; // Accumulate hours
        });
      });
    });

    return totalHours.toFixed(2); // Return total with two decimal places
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await fetchActiveUsers();
      setActiveUsers(users);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    let newStartDate = startDate; // Use a local variable for calculation
    let newEndDate = startDate; // Initialize newEndDate based on startDate

    // Adjust date range based on selectedOption
    if (selectedOption === 'day') {
      // For a single day, min and max dates are the same
      newEndDate = newStartDate;
    } else if (selectedOption === 'week') {
      // For a week, calculate the start and end of the week
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - newStartDate.getDay()); // Start of the week
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newEndDate.getDate() + 6); // End of the week
    } else if (selectedOption === 'month') {
      // For a month, calculate the start and end of the month
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // Start of the month
      newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0); // End of the month
    }

    // Only update the state if necessary to avoid re-triggering the effect
    if (newStartDate.getTime() !== startDate.getTime()) {
      setStartDate(newStartDate); // Update startDate only if it's different
    }
    if (newEndDate.getTime() !== endDate?.getTime()) {
      setEndDate(newEndDate); // Update endDate only if it's different
    }
  }, [selectedOption, startDate, endDate]);


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const fetchData = async () => {
    if (selectedUser && startDate && endDate) {
      // Format the dates as required by your API
      const min_date = startDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      const max_date = endDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      console.log('Fetching data for:', { min_date, max_date });

      // Use the formatted dates in your API call
      const data = await fetchUserTimesheetData(selectedUser, min_date, max_date);

      // Update the state with the fetched data
      setTimesheetData(data);
    }
  };



  // const fetchData = async () => {
  //   if (selectedUser && startDate && endDate) {
  //     const data = await fetchUserTimesheetData(
  //       selectedUser,
  //       startDate.toDateString(),
  //       endDate.toDateString()
  //     );
  //     setTimesheetData(data);
  //   }
  // };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleExportCSV = () => {
    // Helper to convert array of objects into CSV format
    const convertToCSV = (rows) => {
      const csvContent = rows.map(row =>
        Object.values(row).map(value => `"${value}"`).join(",")
      ).join("\n");
      return csvContent;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'short' }); // "Jun" for June
      const day = String(d.getDate()).padStart(2, '0'); // "01" for day 1
      const year = d.getFullYear(); // 2024
      return `${month}_${day}_${year}`;
    };

    if (!timesheetData || !Object.keys(timesheetData).length) {
      console.error("No data available for export.");
      return;
    }

    const projectRows = [];
    const taskRows = [];
    let userName = '';

    // Iterate over the project data
    Object.keys(timesheetData).forEach((projectId) => {
      const project = timesheetData[projectId];
      const projectName = project.projectName;

      Object.keys(project.users).forEach((userId) => {
        const user = project.users[userId];
        userName = user.name; // Capture the user name to use as a heading
        let totalProjectHours = 0;

        // For each task under the user
        Object.keys(user.hours).forEach((taskId) => {
          const task = user.hours[taskId];
          totalProjectHours += task.hour;

          // Create a row for the task table
          taskRows.push({
            TaskName: task.name,
            TaskHours: task.hour,
          });
        });

        // Create a row for the project table
        projectRows.push({
          ProjectName: projectName,
          ProjectHours: totalProjectHours,
        });
      });
    });

    // Convert both tables to CSV format
    const projectCSV = convertToCSV([['Project Name', 'Hours'], ...projectRows.map(row => [row.ProjectName, row.ProjectHours])]);
    const taskCSV = convertToCSV([['Task Name', 'Hours'], ...taskRows.map(row => [row.TaskName, row.TaskHours])]);

    // Combine CSV tables with a header for the user's name
    const finalCSV = `${userName}'s Timesheet:\n\nProject Table:\n${projectCSV}\n\nTask Table:\n${taskCSV}`;

    // Format the dates for the filename
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    // Create a blob and initiate a download
    const blob = new Blob([finalCSV], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${userName}_Timesheet_${formattedStartDate}_to_${formattedEndDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const adjustDates = (direction) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    if (direction === 'next') {
      if (selectedOption === 'day') {
        newStartDate.setDate(newStartDate.getDate() + 1);
        newEndDate.setDate(newEndDate.getDate() + 1);
      } else if (selectedOption === 'week') {
        newStartDate.setDate(newStartDate.getDate() + 7);
        newEndDate.setDate(newEndDate.getDate() + 7);
      } else if (selectedOption === 'month') {
        newStartDate.setMonth(newStartDate.getMonth() + 1);
        newEndDate.setMonth(newEndDate.getMonth() + 1);
      }
    } else if (direction === 'prev') {
      if (selectedOption === 'day') {
        newStartDate.setDate(newStartDate.getDate() - 1);
        newEndDate.setDate(newEndDate.getDate() - 1);
      } else if (selectedOption === 'week') {
        newStartDate.setDate(newStartDate.getDate() - 7);
        newEndDate.setDate(newEndDate.getDate() - 7);
      } else if (selectedOption === 'month') {
        newStartDate.setMonth(newStartDate.getMonth() - 1);
        newEndDate.setMonth(newEndDate.getMonth() - 1);
      }
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const processData = (data) => {
    if (!selectedUser || !data || !Object.keys(data).length) {
      return { taskDistribution: [], projectDistribution: [] };
    }

    const taskDistribution = [];
    const projectDistribution = [];

    Object.values(data).forEach((project) => {
      const user = project.users[selectedUser];
      if (!user) return;

      const userTasks = user.hours;

      // Aggregate task distribution
      Object.values(userTasks).forEach((task) => {
        const shortTaskName = getShortNameForTask(task.name);
        const mediumTaskName = getMediumNameForTask(task.name); // Add medium names for tooltips
        const existingTask = taskDistribution.find((t) => t.shortName === shortTaskName);
        if (existingTask) {
          existingTask.value += task.hour;
        } else {
          taskDistribution.push({
            shortName: shortTaskName,    // Short name for the legend
            mediumName: mediumTaskName,  // Medium name for the tooltip
            value: task.hour,
          });
        }
      });

      // Aggregate project distribution
      const totalProjectHours = Object.values(userTasks).reduce(
        (sum, task) => sum + task.hour,
        0
      );
      const shortProjectName = getShortNameForProjects(project.projectName);
      const mediumProjectName = getMediumNameForProjects(project.projectName); // Medium name for tooltips

      const existingProject = projectDistribution.find((p) => p.shortName === shortProjectName);
      if (existingProject) {
        existingProject.value += totalProjectHours;
      } else {
        projectDistribution.push({
          shortName: shortProjectName,    // Short name for the legend
          mediumName: mediumProjectName,  // Medium name for the tooltip
          value: totalProjectHours,
        });
      }
    });

    return { taskDistribution, projectDistribution };
  };



  const renderCharts = () => {
    if (!selectedUser) return null;

    const { taskDistribution, projectDistribution } = processData(timesheetData);

    if (
      (!taskDistribution || taskDistribution.length === 0) &&
      (!projectDistribution || projectDistribution.length === 0)
    ) {
      return (
        <div className="flex justify-center items-center h-64">
          <h2 className="text-xl font-semibold text-gray-500">
            No timesheet data found.
          </h2>
        </div>
      );
    }

    return (
      <div className="flex gap-4 pt-5">
        {/* Task Distribution */}
        <div className="w-full">
          <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text pb-1">
            Task Distribution
          </h1>

          <ChartContainer>
            <ResponsiveContainer width="100%" height={500} className={"flex-1"}>
              <PieChart>
                <Pie
                  data={taskDistribution}
                  dataKey="value"
                  nameKey="shortName"  // Display short name in the legend
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  innerRadius={100}
                  paddingAngle={5}
                  fill="#8884d8"
                  label={false}
                >
                  {taskDistribution.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name, entry) => {
                    const mediumName = entry.payload.mediumName;  // Show medium name in tooltip
                    return [`${value} hours`, mediumName];
                  }}
                />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: "block",
                    width: "100%",
                    columnCount: 1,
                    overflow: "auto",
                    maxHeight: "100px",
                  }}
                  iconType="circle"
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>

        {/* Project Distribution */}
        <div className="w-full">
          <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text pb-1">
            Project Distribution
          </h1>

          <ChartContainer>
            <ResponsiveContainer width="100%" height={500} className={"flex-1"}>
              <PieChart>
                <Pie
                  data={projectDistribution}
                  dataKey="value"
                  nameKey="shortName"  // Display short name in the legend
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  innerRadius={100}
                  paddingAngle={5}
                  fill="#82ca9d"
                  label={false}
                >
                  {projectDistribution.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name, entry) => {
                    const mediumName = entry.payload.mediumName;  // Show medium name in tooltip
                    return [`${value} hours`, mediumName];
                  }}
                />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: "block",
                    width: "100%",
                    columnCount: 1,
                    overflow: "auto",
                    maxHeight: "100px",
                  }}
                  iconType="circle"
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
      </div>
    );
  };


  useEffect(() => {
    fetchData();
  }, [selectedUser, startDate, endDate]);

  return (
    <StyledContainer>
      <div className="relative">
        <Title>
          <h1 className="sticky top-0 mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              User
            </span>{" "}
            Analysis
          </h1>
          <div
            style={{
              borderBottom: '2px solid #0d47a1',
              marginBottom: '20px',
            }}
          ></div>
        </Title>

        {/* Sticky Nav */}
        <div className="sticky top-0 bg-white z-10 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6 p-4">
          {/* Date Range Dropdown */}
          <FormControl style={{ width: '100%', maxWidth: '150px' }}>
            <InputLabel id="date-range-label">Select Range</InputLabel>
            <Select
              labelId="date-range-label"
              id="date-range"
              label="Select Range"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
            </Select>
          </FormControl>

          {/* Conditionally Render DatePicker */}
          <div style={{ flex: 1 }}>
            {selectedOption === 'month' ? (
              <DatePicker
                views={['year', 'month']}
                label="Select Month"
                value={startDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            ) : (
              <DatePicker
                label="Select Date"
                value={startDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
          </div>

          {/* User Selection */}
          <FormControl style={{ width: '100%', maxWidth: '400px' }}>
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              className="capitalize"
              value={selectedUser || ""}
              onChange={handleUserChange}
              label="Select User"
            >
              {activeUsers.map((user) => (
                <MenuItem key={user.uid} value={user.uid}>
                  {user.users_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Navigation Buttons */}
          <div className="flex space-x-2">
            <Button variant="outlined" onClick={() => adjustDates('prev')}>
              Previous
            </Button>
            <Button variant="outlined" onClick={() => adjustDates('next')}>
              Next
            </Button>
          </div>

          {/* Export CSV Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExportCSV}
            disabled={!selectedUser || !Object.keys(timesheetData).length}
          >
            Export CSV
          </Button>
        </div>


    {/* Render Charts */}
    {renderCharts()}
   {Object.keys(timesheetData).length > 0 && (
  <div className="mt-4 p-4 flex justify-center">
    <h2 className="sticky top-0 mb-4 text-3xl font-bold text-gray-900 dark:text-black md:text-5xl lg:text-5xl">
            <span className="text-transparent bg-clip-text bg-gray-900">
              Overall Time:
            </span>{" "}
            {calculateTotalHours()} hours
          </h2>
    {/* <p className="text-3xl font-bold ml-2 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
      {totalHours.toFixed(2)} hours
    </p> */}
  </div>
)}

    <div className="mt-4">
      <div className="flex gap-4">
        <p
          className={`border-2 rounded-2xl px-6 py-2 cursor-pointer ${selectedView === 'project' ? 'bg-orange-600 text-white' : 'border-orange-600'}`}
          onClick={() => setSelectedView('project')}
        >
          Project View
        </p>
        <p
          className={`border-2 border-red-500 rounded-2xl px-6 py-2 cursor-pointer ${selectedView === 'task' ? 'bg-red-600 text-white' : ''}`}
          onClick={() => setSelectedView('task')}
        >
          Task View
        </p>
      </div>

      {/* Conditionally render views based on selectedView */}
      {selectedView === 'project' && <TimeSheetTable data={timesheetData} />}
      {selectedView === 'task' && <TaskViewTable data={timesheetData} />}
    </div>
   {/* Total Hours Section */}

      </div>
    </StyledContainer>
  );
};


export default TimesheetDashboard;

const TimeSheetTable = ({ data }) => {
  return (
    <div className="p-4">
      {Object.keys(data).map((projectId) => {
        const project = data[projectId];

        // Calculate the total hours for each project
        let totalHours = 0;
        Object.keys(project.users).forEach((userId) => {
          const user = project.users[userId];
          Object.keys(user.hours).forEach((taskId) => {
            totalHours += parseFloat(user.hours[taskId].hour) || 0;
          });
        });

        return (
          <div key={projectId} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              {project.projectName}
            </h2>
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left w-3/4">
                    Task Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left w-1/4">
                    Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(project.users).map((userId) => {
                  const user = project.users[userId];
                  return Object.keys(user.hours).map((taskId, index) => {
                    const task = user.hours[taskId];
                    return (
                      <tr
                        key={taskId}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="border border-gray-300 px-4 py-2">
                          {task.name}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">
                          {task.hour}
                        </td>
                      </tr>
                    );
                  });
                })}

                {/* Add total row */}
                <tr className="bg-yellow-100 font-bold">
                  <td className="border border-gray-300 px-4 py-2">Total</td>
                  <td className="border border-gray-300 px-4 py-2">{totalHours.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

const TaskViewTable = ({ data }) => {
  // Create a structure that aggregates tasks across all projects
  const tasksMap = {};
  const userHoursMap = {};

  Object.keys(data).forEach((projectId) => {
    const project = data[projectId];
    Object.keys(project.users).forEach((userId) => {
      const user = project.users[userId];
      Object.keys(user.hours).forEach((taskId) => {
        const task = user.hours[taskId];
        const shortTaskName = getShortNameForTask(task.name); // Assuming getShortNameForTask is imported

        // Ensure task hours are added properly without overwriting
        if (tasksMap[taskId]) {
          tasksMap[taskId].hours += parseFloat(task.hour); // Make sure to parse the value to avoid incorrect aggregation
        } else {
          tasksMap[taskId] = { name: shortTaskName, fullName: task.name, hours: parseFloat(task.hour) };
        }

        // Aggregate hours per user
        if (!userHoursMap[userId]) {
          userHoursMap[userId] = { name: user.name, tasks: {}, total: 0 };
        }

        // Ensure user task hours are added properly without overwriting
        userHoursMap[userId].tasks[taskId] = (userHoursMap[userId].tasks[taskId] || 0) + parseFloat(task.hour);
        userHoursMap[userId].total += parseFloat(task.hour);
      });
    });
  });

  // Extract the task names
  const taskIds = Object.keys(tasksMap);
  const taskNames = taskIds.map((taskId) => tasksMap[taskId]);

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left bg-yellow-200 font-bold">Total</th> {/* Highlighted Total */}
            {taskNames.map((task, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2 text-left">
                <div className="tooltip">
                  {task.name}
                  <span className="tooltiptext">{task.fullName}</span> {/* Tooltip for full task name */}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(userHoursMap).map((userId) => {
            const user = userHoursMap[userId];
            return (
              <tr key={userId} className="bg-white odd:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{user.name}</td>
                <td className="border border-gray-300 px-4 py-2 bg-yellow-200 font-bold">{user.total.toFixed(1)}</td> {/* Highlighted Total */}
                {taskIds.map((taskId) => (
                  <td key={taskId} className="border border-gray-300 px-4 py-2">
                    {user.tasks[taskId]?.toFixed(1) || '0.0'} {/* Ensure decimal precision */}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
const userMappingFirstNames = {
  "FSINT030": "Sharon Flora",
  "FSINT027": "Lavan Vasanthakumar",
  "FSINT026": "Ramanathan Subramanian",
  "FSINT029": "Thanush Babu",
  "FSINT025": "Aathi Eswar Sivasankaran",
  "FSINT028": "Amritha Arun",
  "FS-0196": "Ashish Sharma",
  "FS0195": "Nitesh Soni",
  "FS0194": "Thejo Varma",
  "WAX002": "Radha",  // If Radha is unchanged
  "FS - Con - 009": "Balaji Rengan",

  "WAX001": "Brian",  // If Brian is unchanged
  "FS-Con-006": "Pradeep Kumar",
  "FSF028": "Sneha Mannarsamy",
  "FS0188": "Adithya Ravi",
  "FS0197": "Akhila Sunil Kumar",
  "FS0198": "Harini Karthikeyan",
  "FS0200": "Shalini Raghuraja",
  "FS0199": "Krithikaa Jothiprakash",
  "FS0201": "Snehapriyaa Paramashivam",
  "FS0183": "Roopesh Dhananjayalu",
  "FS0179": "Priyanka Saravanan",
  "FS0176": "Manikandan Padbanamaban",
  "FS0174": "Sai Ram Muralikrishnan",
  "FS0189": "Abhilaash Jaishankar",
  "FSAcc01": "Accounts",  // If Accounts is unchanged
  "FS0187": "Siva Kumar Sanguraj",
  "FS0169": "Abhishek Kushwaha",
  "FS0116": "Pushparaj Thirupal",
  "FS-CEO-001": "Venu",  // If Venu is unchanged
  "FS-Con-002": "Sorathiya Vidhyut",
  "FSF-002": "Munusamy Chandran",
  "FS0186": "Sai Ramya Mohanraj",
  "FS0184": "Sanmukapriya Kamalakannan",
  "FS0140": "Alex Surya Rayappan",
  "FS0114": "Arnab Sinha Roy",
  "FS0097": "Glory Victor",
  "FS0083": "Sathish Jayakumar",
  "FS0082": "Swathi Sridhar",
  "FS0162": "Isha Agarwal",
  "FS0190": "Komathi Sundaramurthy",
  "FS0181": "Subash Kangamani",
  "FS0010": "Naren Ethiraj",
  "FS0008": "Deepa Krishnamoorthy",
  "FS0159": "Thangaraj Manthiriyappan",
  "FS0056": "Rajeswari Kalisamy",
  "FS0069": "Shriram Thirukumaran",
  "FS0005": "Latha",  // If Latha is unchanged
  "FS0203" : "Vineesh Koppay",
  "FSS003": "Office Helper"  // If Office Helper is unchanged
};

export default userMappingFirstNames;
